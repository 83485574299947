import React, { Component } from "react";
import { connect } from "react-redux";
import { scrollValueFunction, handleSubCategoryData, handleFloorPlanClick } from "./../redux/actions";
import "./three.css";
import screenfull from "screenfull";
import CubeAdd from "../threejs/cube";
import autoplayStartIcon from "../images/autoplay-start.svg";
import gyroOn from "../images/gyroOn.svg";
import gyroOff from "../images/gyroOff.svg";
import gCardboard from "../images/gCardboard.svg";
import fullScreenOn from "../images/fullScreenOn.svg";
import fullScreenOff from "../images/fullScreenOff.svg";
// import ScrollableTabs from "../components/ScrollableTabs/ScrollableTabs";
import Annotation from "./Annotation";
import MainCategory from "./MainCategory/MainCategory";
import SubCategory from "./SubCategory/SubCategory"
// import FloorPlan from "./FloorPlan/FloorPlan"

const autoatuoplay = window.location.search === "?autoplay=true";
var cubee;

class ThreeMain extends Component {
  state = {
    thumbnails: false,
    mouseIdle: false,
    fullScreen: false,
    gyro: false,
    touchable: false,
    isVr: false,
    isVrDisplay: false,
    logoHeight: 0,
    autoplayenabled: false,
  };

  componentDidMount = () => {
    console.log(autoatuoplay)
    window.screen.width <= 400
      ? this.setState({ logoHeight: 50 })
      : this.setState({ logoHeight: 60 });

    this.props.handleSubCategoryData(this.props.categorydata);
    cubee = new CubeAdd(
      this.props.sceneData,
      this.props.annotationData,
      this.props.mainCategoryData,
      this.props.brandColor,
      this.disableAutoplayButton
    );
    let sceneid = this.props.sceneData[0].sceneId;
    let firstScenee = this.props.firstScene;
    if (firstScenee) {
      sceneid = firstScenee.sceneId;
    }
    let indexforpos = this.props.sceneData.findIndex(
      (n) => n.sceneId === sceneid
    );
    let position = this.props.sceneData[indexforpos].position;

    cubee.generateCube(sceneid, 1).then((x) => {
      if (firstScenee) {
        cubee._controls.moveTo(position.x, position.y, position.z);
        cubee._controls.rotateTo(firstScenee.camRotation, 1.5708, false);
      }
    });

    this.idleTime = setInterval(() => {
      this.setState((prevState) => {
        return prevState.thumbnails ? null : { mouseIdle: true };
      });
    }, 1000);

    // document.body.addEventListener("mousemove", this.handleMouseMove);
    document.body.addEventListener("touchstart", this.handleMouseMove);

    //Fullscreen event
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        if (screenfull.isFullscreen) {
          this.setState({
            fullScreen: true,
          });
        } else {
          this.setState({
            fullScreen: false,
          });
        }
      });
    }

    window.addEventListener("devicemotion", this.handleTouchable);
    navigator.getVRDisplays().then((displays) => {
      if (displays.length) {
        this.setState({ isVrDisplay: true });
      }
    });
    setTimeout((_) => {
      window.removeEventListener("devicemotion", this.handleTouchable);
    }, 1000);
    if (autoatuoplay) {
      this.handleAutoPlay();
    }
  };



  handleTouchable = (e) => {
    if (e.rotationRate.alpha || e.rotationRate.beta || e.rotationRate.gamma) {
      this.setState({
        devicemotion: e,
        touchable: true,
      });
    }
  };

  handleMouseMove = () => {
    // console.log('handleMouseMove');
    if (this.state.mouseDrag) {
      return null;
    }
    this.setState({
      mouseIdle: false,
    });
    clearInterval(this.idleTime);
    this.idleTime = setInterval(() => {
      this.setState((prevState) => {
        return prevState.thumbnails ? null : { mouseIdle: true };
      });
    }, 4000);
  };

  handleFocusChange = (data) => {
    this.setState({
      thumbnails: data,
    });
  };

  hideThumbnails = () => {
    this.setState({ thumbnails: false });
  };

  handleSceneChange = (id) => {
    if (this.state.autoplayenabled) {
      cubee.stopAutoplay();
    }
    this.setState({
      autoplayenabled: false,
    });
    cubee.cameraTeleport(id);
  };

  handleMouseDown = () => {
    // console.log("mouse is down");
    this.setState({
      mouseDrag: true,
      mouseIdle: true,
      thumbnails: false,
    });
  };

  handleMouseUp = () => {
    // console.log("mouse is up");
    if (this.state.autoplayenabled) {
      cubee.stopAutoplay();
    }
    this.setState({
      mouseDrag: false,
      mouseIdle: false,
      autoplayenabled: false,
    });
  };

  handleFullscreen = (e) => {
    if (
      document.fullscreenEnabled || /* Standard syntax */
      document.webkitFullscreenEnabled || /* Chrome, Safari and Opera syntax */
      document.mozFullScreenEnabled ||/* Firefox syntax */
      document.msFullscreenEnabled/* IE/Edge syntax */
    ) {
      console.log("supported")
    } else {

      function inIframe() {
        try {
          return window.self !== window.top;
        } catch (e) {
          return true;
        }
      }
      if (inIframe) {
        window.open(`${window.location.origin}${window.location.pathname}?autostart=true`);
      }
    }

    if (screenfull.isEnabled) {
      if (screenfull.isFullscreen) {
        screenfull.exit();
      } else {
        screenfull.request();
      }
    }

  };

  handleBtnAnim = (e) => {
    let el = e.currentTarget;
    el.classList.add("red");
    setTimeout((_) => el.classList.remove("red"), 200);
  };

  handleGyro = (e) => {
    this.handleBtnAnim(e);
    this.setState((prevState) => {
      cubee.gyroEnable(prevState.gyro);
      return {
        gyro: !prevState.gyro,
      };
    });
  };

  handleVr = () => {
    cubee.handleVr(this.state.isVr);
    this.setState({ isVr: !this.state.isVr });
  };

  handleAutoPlay = () => {
    if (!this.state.autoplayenabled) {
      this.setState({
        autoplayenabled: true,
        mouseIdle: true,
      });
      cubee.autoplayHandler(500);
    } else {
      cubee.stopAutoplay();
      this.setState({
        autoplayenabled: false,
        mouseIdle: false,
      });
    }
  };

  disableAutoplayButton = () => {
    this.setState({
      autoplayenabled: false,
    });
  };

  handleFloorPlanClick = () => {
    this.props.handleFloorPlanClick(!this.props.isFloorPlanEnabled)
  }

  render() {
    const {
      companyLogoUrl,
      mainCategoryData,
      isAerialView,
      brandColor,
      isVr,
      subCategoryData,
      autoplay,
    } = this.props;
    const {
      autoplayenabled,
      mouseIdle,
      thumbnails,
      fullScreen,
      gyro,
      touchable,
      isVrDisplay,
      logoHeight,
    } = this.state;
    let loadmainCategoryData =
      mainCategoryData && mainCategoryData.data.length !== 0;
    return (
      <React.Fragment>

        {/* <FloorPlan 
        handleSceneChange={this.handleSceneChange}
        /> */}

        <Annotation />

        {
          loadmainCategoryData && !isVr && (
            <MainCategory
              handleSceneChange={this.handleSceneChange}
              brandColor={brandColor}
              isAutoplayEnabled={autoplay}
            />
          )
        }

        {!mainCategoryData ? (

          <SubCategory
            categoryData={subCategoryData}
            thumbnails={thumbnails}
            handleSceneChange={this.handleSceneChange}
            brandColor={brandColor}
          />

        ) : null
        }

        <img
          src={companyLogoUrl}
          alt="logo"
          className={
            thumbnails || mouseIdle ? "topNav logo topNavIdle" : "topNav logo"
          }
          style={{ height: logoHeight }}
        />

        <div
          id="render-id"
          onClick={this.hideThumbnails}
          onMouseMove={this.handleMouseMove}
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          // onTouchStart={this.hideThumbnails}
          onTouchStart={this.handleMouseDown}
          onTouchEnd={this.handleMouseUp}
          style={{ width: "100vw", height: "100vh", position: "fixed" }}
        />
        {autoplay ? (
          <button onClick={this.handleAutoPlay}>
            <img
              className={
                thumbnails || mouseIdle
                  ? "bottomNav  autoplay"
                  : "bottomNav autoplay"
              }
              style={{ display: autoplayenabled ? "none" : "inherit" }}
              src={autoplayStartIcon}
              alt="autoplay"
            />

            <svg
              style={{ display: !autoplayenabled ? "none" : "inherit" }}
              className="progress-ring bottomNav autoplay "
              className={
                thumbnails || mouseIdle
                  ? "progress-ring bottomNav autoplay autoplayIdle"
                  : "progress-ring bottomNav autoplay "
              }
              width="32px"
              height="32px"
            >
              <circle
                className="progress-ring__circle_default "
                stroke="white"
                strokeWidth="3"
                fill="transparent"
                r="11px"
                cx="16px"
                cy="16px"
              />
              <circle
                id="progress-ring"
                className="progress-ring__circle "
                stroke={brandColor}
                strokeWidth="3"
                fill="transparent"
                r="11px"
                cx="16px"
                cy="16px"
              />
            </svg>
          </button>
        ) : null
        }
        <div
          className={
            thumbnails || mouseIdle
              ? "bottomNav bottomNavIdle btn-group"
              : "bottomNav btn-group"
          }
        >
          {touchable ? (
            <button
              className={
                touchable ? "icon-container gyro touch" : "icon-container gyro"
              }
              onTouchStart={this.handleBtnAnim}
              onClick={this.handleGyro}
            >
              <img id="gyro-id" src={gyro ? gyroOn : gyroOff} alt="gyro" />
            </button>
          ) : null}
          {isVrDisplay ? (
            <button
              className="icon-container  gCardboard"
              onClick={this.handleVr}
            >
              <img src={gCardboard} alt="gCardboard" />
            </button>
          ) : null}
          <button
            className={touchable ? "icon-container touch" : "icon-container"}
            onTouchStart={this.handleBtnAnim}
            onClick={this.handleFullscreen}
          >
            <img
              src={fullScreen ? fullScreenOff : fullScreenOn}
              alt="helpIcon"
              className="helpIcon"
            />
          </button>
        </div>


      </React.Fragment >
    );
  }
}

function mapStateToProps(state) {
  return {
    mainCategoryData: state.sceneDataReducer.mainCategoryData,
    annotationData: state.sceneDataReducer.annotationData,
    sceneData: state.sceneDataReducer.sceneItems,
    categorydata: state.sceneDataReducer.catagoryData,
    companyLogoUrl: state.sceneDataReducer.info.companyLogoUrl,
    firstScene: state.sceneDataReducer.firstScene,
    isVr: state.scrollValueReducer.isVr,
    brandColor: state.sceneDataReducer.brandColor,
    subCategoryData: state.mainCategoryReducer.subCategoryData,
    autoplay: state.sceneDataReducer.autoplay,
    isFloorPlanEnabled: state.handleFloorPlanClickReducer.isEnabled
  };
}

function mapDispatchToProps(dispatch) {
  return {
    scrollValueFunction: (data) => dispatch(scrollValueFunction(data)),
    handleSubCategoryData: (data) => dispatch(handleSubCategoryData(data)),
    handleFloorPlanClick: (data) => dispatch(handleFloorPlanClick(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThreeMain);
