
import {
  PerspectiveCamera,
  Scene,
  Clock,
  Vector3,
  // Euler,
  // Quaternion,
  WebGLRenderer,
  Object3D,
  Raycaster,
  Math,
  Vector2,
  RingBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  Color,
  // SceneUtils
} from 'three';
import WebVRPolyfill from 'webvr-polyfill';
// import Stats from 'stats-js';
import CameraControls from './cameracontrols/cameraControls';
import store from '../redux/store';
import {vrEnableFunction} from '../redux/actions';

const TWEEN = require('@tweenjs/tween.js');

const polyfill = new WebVRPolyfill();
const deg2rad = Math.PI / 180

class ThreeScene {
  constructor () {
    this.container = document.getElementById("render-id")
    this._camera = new PerspectiveCamera(90, this.container.offsetWidth/ this.container.offsetHeight, 0.1, 1000)
    this._camera.position.set(0,0,0)

    this._scene = new Scene();
    this._clock = new Clock();

    this.raycaster = new Raycaster();
    this.vrRaycaster = new Raycaster();
    this.mouse = new Vector2();

    this.isVr=false

    this._renderer =  new WebGLRenderer({ antialias: true });
    this._renderer.setSize(this.container.offsetWidth, this.container.offsetHeight);
    this._renderer.setPixelRatio(window.devicePixelRatio);
    this.container.appendChild(this._renderer.domElement)

    // this._stats = new Stats();
    // this.container.appendChild( this._stats.dom );

    this.groupCamera=new Object3D()
    this.groupCamera.name='groupCamera'
    this.groupCamera.add(this._camera)
    this.groupCamera.position.set(0,0,0.0001)
    this._scene.add(this.groupCamera)

    //crosshair in WebVr
    this.crosshair = new Mesh(
				new RingBufferGeometry(0.02, 0.04, 100 ),
				new MeshBasicMaterial( {
					color: 0xffffff,
					opacity: 0.5,
					transparent: true
				} )
			);
		this.crosshair.position.z = -2;
    this.crosshair.name="crosshair"
    this.crosshair.visible=false
    this._camera.add(this.crosshair)

    this._controls = new CameraControls(this.groupCamera, this._renderer.domElement);
    this._controls.maxDistance=0.75
    if(window.innerWidth <= 450){
      this._controls.phiSpeed = 0.2;
      this._controls.thetaSpeed = 0.2;
      this._controls.draggingDampingFactor = 0.36;
    }
    this._controls.phiSpeed = 0.4;
    this._controls.thetaSpeed = 0.4;
    this._controls.draggingDampingFactor = 0.30;

    this.gyro = false;
    this.gyroEnable(true)

    this.annotations=new Object3D()
    this.aerialPoints= new Object3D()
    this.aerialPoints.scale.set(-1,1,1)

    window.addEventListener('resize', this.onWindowResize.bind(this), false);
    document.getElementById("render-id").addEventListener("wheel", this.aerialZoomHandle,true);
    window.addEventListener( 'vrdisplaypresentchange',this.vrdisplaypresentchange.bind(this) , false );

    // set vr device if exist
    navigator.getVRDisplays().then(displays => {
      this.displays=displays
      if (displays.length) {
        this.displays[0].bufferScale_=1
        this._renderer.vr.setDevice( this.displays[0] );
        this.displays[0].deviceInfo_.viewer.interLensDistance = 0.048;
      }
    });
  }

  aerialZoomHandle=(event)=>{
    //  AerialView ZoomIN & ZoomOUT   //
    if (event.deltaY>0 && this._camera.fov!==120) {
      this._camera.fov += 3
      this._camera.updateProjectionMatrix()
    }
    else if (event.deltaY<0 && this._camera.fov!==60)  {
      this._camera.fov -= 3
      this._camera.updateProjectionMatrix()
    }
  }

  vrdisplaypresentchange( event ) {
    if (event.detail.display.isPresenting) {
      this._camera.fov=90
      this._controls.enabled=false
    }
    else {
      this._controls._sphericalEnd.set(1e-7, 1.5707963267948966, 0)
      this._controls._spherical.set(1e-7, 1.5707963267948966, 0)
      this._camera.rotation.set(0,0,0)
      this.crosshair.visible=false
      store.dispatch(vrEnableFunction(false))
      this.isVr=false
       if (this.gyro === false) {
        this.gyroEnable(true)
        this._controls.enabled=true
      }
    }
  }

  handleVr(x){
    if (this.displays.length) {
    this._controls._sphericalEnd.set(1e-7, 1.5707963267948966, 0)
    this._controls._spherical.set(1e-7, 1.5707963267948966, 0)
    this._camera.rotation.set(0,0,0)
    this.crosshair.visible=true
    store.dispatch(vrEnableFunction(true))
    this.isVr=true
    this._renderer.vr.enabled=true
    this.displays[0].requestPresent( [ { source: this._renderer.domElement } ] )
    } else {
      console.log('vr device not found');
    }
  }

  gyroEnable(x){
    this._controls._sphericalEnd.set(1e-7, 1.5707963267948966, 0)
    this._controls._spherical.set(1e-7, 1.5707963267948966, 0)
    this._camera.rotation.set(0,0,0)
    if(!x){
      this._renderer.vr.enabled=true
      this._controls.enabled=false
      this.gyro=true
    } else {
      this._controls.enabled=true
      this._renderer.vr.enabled=false
      this.gyro=false
    }
  }

  get renderer () {
    return this._renderer
  }

  get camera () {
    return this._camera
  }

  get scene () {
    return this._scene
  }

  onWindowResize () {
    this._camera.aspect = this.container.offsetWidth/ this.container.offsetHeight
    this._camera.updateProjectionMatrix()
    this._renderer.setSize(this.container.offsetWidth, this.container.offsetHeight)
  }

  cameraAnimation(pos,animation) {
    for (let i = 0; i < 6; i++) {
      new TWEEN.Tween(this._scene.children[2].material[i])
      .to({ opacity: 0 }, 1000)
      .easing(TWEEN.Easing.Linear.None)
      .start();
      new TWEEN.Tween(this._scene.children[3].material[i])
      .to({ opacity: 1 }, 1000)
      .easing(TWEEN.Easing.Linear.None)
      .start();
      if (this._camera.fov !== 90) {
        new TWEEN.Tween(this._camera)
        .to({fov:90}, 1000)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate(() => { this._camera.updateProjectionMatrix ()  })
        .start();
      }
    }
    if(animation){
      var b = new Vector3(pos.x, pos.y, pos.z);
      new TWEEN.Tween(this._controls._targetEnd)
        .to(b, 1000)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onComplete(() => {
          this.disposeCube();
        })
        .start();
    } else {
      this._controls.moveTo(pos.x, pos.y, pos.z,false)
      this.disposeCube();
    }

  }

  animate (timestamp) {
    this.isVr ?
      this.displays[0].requestAnimationFrame(this.animate.bind(this))
      :
      requestAnimationFrame(this.animate.bind(this))

      let delta = this._clock.getDelta();
      this._controls.update(delta);
      this._renderer.render(this._scene, this._camera)
      // this._stats.update();

    //   WebVr Hotspot Tween and Cube Transition  //
    if (this.hotspotempty && this.isVr) {
      if ( this.selectedVrObject &&  !this.isUp) {
        this.recticleOffItem(this.selectedVrObject)
        this.selectedVrObject = null;
      }
      this.vrRaycaster.setFromCamera( {x:0,y:0}, this._camera );
      var intersects = this.vrRaycaster.intersectObject( this.hotspotempty, true );
      if ( intersects.length > 0 ) {
        var res = intersects.filter( function ( res ) {
          return res && res.object;
        } )[ 0 ];
        if ( res && res.object && !this.isDown) {
          this.selectedVrObject = res.object;
          this.recticleOnItem(this.selectedVrObject)
        }
      }
      else {
        this.transitionAllow=false
      }
    }
     //     Aerial View Stuffs        //
    //    Raycast on Aerial Spots   //
    if (this.aerialPoints.children.length && !this.isVr) {
      if (this.selectedObject ) {
        this.selectedObject.parent.children[2].visible=false
        this.selectedObject.parent.children[3].visible=false
        this.selectedObject.parent.children[4].visible=false
        this.selectedObject.parent.children[0].material.color=new Color( 0xfafafa )
        this.selectedObject.parent.children[1].material.color=new Color( 0xfafafa )
        this.selectedObject = null;
      }
      this.raycaster.setFromCamera(this.mouse, this._camera);
      let intersects = this.raycaster.intersectObjects( this.aerialPoints.children,true );
      if ( intersects.length > 0 ) {
        var res = intersects.filter( function ( res ) {
          return res && res.object;
        } )[ 0 ];
        if ( res && res.object ) {
          this.selectedObject = res.object;
          this.selectedObject.parent.children[2].visible=true
          this.selectedObject.parent.children[3].visible=true
          this.selectedObject.parent.children[4].visible=true
          this.selectedObject.parent.children[0].material.color=new Color(0x9e9e9e)
          this.selectedObject.parent.children[1].material.color=new Color(0x9e9e9e)
        }
      }
    }

    //     Aerial View Stuffs        //
   //    Raycast on Aerial Spots on Vr  //
    if (this.aerialPoints.children.length && this.isVr) {
      if (this.selectedObject && !this.upAerialSpot) {
        this.selectedObject.parent.children[2].visible=false
        this.selectedObject.parent.children[3].visible=false
        this.selectedObject.parent.children[4].visible=false
        this.selectedObject.parent.children[0].material.color=new Color( 0xfafafa )
        this.selectedObject.parent.children[1].material.color=new Color( 0xfafafa )
        this.recticleOffAerialSpotTransition()
        this.selectedObject = null;
      }
      this.raycaster.setFromCamera({x: 0, y: 0}, this._camera);
      let intersects = this.raycaster.intersectObjects( this.aerialPoints.children,true );
      if ( intersects.length > 0 ) {
        var res = intersects.filter( function ( res ) {
          return res && res.object;
        } )[ 0 ];
        if ( res && res.object && !this.isDownAerialSpot ) {
          this.selectedObject = res.object;
          this.recticleOnAerialSpotTransition()
          this.selectedObject.parent.children[2].visible=true
          this.selectedObject.parent.children[3].visible=true
          this.selectedObject.parent.children[4].visible=true
          this.selectedObject.parent.children[0].material.color=new Color(0x9e9e9e)
          this.selectedObject.parent.children[1].material.color=new Color(0x9e9e9e)
        }
      }
      else {
        this.aerialSpotTransitionallow = false
      }
    }


    TWEEN.update(timestamp);
  }
}

export default ThreeScene;
