import React from 'react';
import './index.css'
import { connect } from "react-redux";
import {annotationVisible, annotationDescriptionSupply} from '../../redux/actions';
import closeIcon from '../../images/close.png'

class Annotation extends React.Component {
  handleAnnotationClick=()=>{
    this.props.annotationVisible(false)
    this.props.annotationDescriptionSupply({title:'',description:'',url:null});
  }
  render() {
    const { url, title, body, isAnnotationVisble } = this.props
    return (
      <div  className="annotationMainContainer"
        style={{
          visibility:isAnnotationVisble ? 'visible' : 'hidden'}}
        >
          <div className="annotationContainer">
            <div className="annotationClose" onClick={this.handleAnnotationClick}>
              <img src={closeIcon}/>
            </div>
            {
              url ?
              <div className="annotationImageContainer">
                <img alt="example" className="annotationImage" src={url} />
              </div> : null
            }
            <div className="annotationTextContainer">
              <h3 className="annotationTitle" >
                {title}
              </h3>
              <p className="annotationText">
                {body}
              </p>
            </div>
          </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAnnotationVisble: state.annotationHandleReducer.isAnnotationVisble,
    body:state.annotationHandleReducer.body,
    title:state.annotationHandleReducer.title,
    url:state.annotationHandleReducer.url,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    annotationVisible: data => dispatch(annotationVisible(data)),
    annotationDescriptionSupply: data => dispatch(annotationDescriptionSupply(data))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Annotation);
