import React, { Component } from 'react'
import "./SubCategory.css"
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { connect } from "react-redux"



class subCategory extends Component {

    state = {
        activeSubcategorySceneID: null
    }

    componentDidMount() {
        this.setState({ activeSubcategorySceneID: this.props.firstScene.sceneId })
        let root = document.documentElement;
        root.style.setProperty('--scroll-color', this.props.brandColor);

        this.handleBottomNavStyle()
        window.addEventListener('resize', this.handleBottomNavStyle)
    }

    handleBottomNavStyle = () => {
        let root = document.documentElement;

        if (window.innerWidth < 1080 && (window.innerHeight < window.innerWidth)) {
            root.style.setProperty('--bottom-nav-right', "160px")
            root.style.setProperty('--bottom-nav-bottom', "30px")
            document.querySelector('.bottomNav').style.display = "block"
        } if (window.innerWidth < 1080 && (window.innerHeight > window.innerWidth)) {
            root.style.setProperty('--bottom-nav-right', "24px")
            root.style.setProperty('--bottom-nav-bottom', "130px")
            document.querySelector('.bottomNav').style.display = "flex"
        } else {
            root.style.setProperty('--bottom-nav-right', "160px")
            root.style.setProperty('--bottom-nav-bottom', "30px")
            document.querySelector('.bottomNav').style.display = "block"
        }

    }

    handleSubCategoryClick = (id) => {
        this.props.handleSceneChange(id, false);
        this.setState({ activeSubcategorySceneID: id })
    }


    render() {

        const { thumbnails, firstScene, categoryData, brandColor } = this.props;
        let firstSceneId = firstScene.sceneId

        return (

            <div className="subcategory-container">

                <div className="header">
                    <span>Places</span>
                </div>

                <SimpleBar className="simple-main-wrapper" style={{ maxHeight: "calc(100vh - 65px)" }}>
                    <div className="category-items">
                        <div className="sub-category active">

                            <div className="subcategory">
                                {categoryData.map((category) => {
                                    let className = 'subcategory-items';
                                    let style = {}
                                    if (this.state.activeSubcategorySceneID === category.SceneID) {
                                        className += ' active';
                                        style = { borderColor: brandColor }
                                    }
                                    return (
                                        <div onClick={() => {
                                            this.handleSubCategoryClick(category.SceneID)
                                        }}
                                            key={category.SceneID}
                                            className={className}
                                            style={style}
                                        >
                                            <img src={category.thumbnailUrl} />
                                            <div className="title">
                                                <label>{category.catagoryName}</label>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>


                        </div>
                    </div>
                </SimpleBar>

            </div >






        )
    }
}

function mapStateToProps(state) {
    return {
        firstScene: state.sceneDataReducer.firstScene
    };
}

export default connect(mapStateToProps)(subCategory);
