export const SCENE_DATA = "SCENE_DATA";

export const DOWNLOAD_COMPLETE_SCENE_IDS = "DOWNLOAD_COMPLETE_SCENE_IDS";

export const SHOW_LOADER = "SHOW_LOADER";
export const REMOVE_HOMEPAGE = "REMOVE_HOMEPAGE";

export const ANNOTATION_VISIBLE="ANNOTATION_VISIBLE"
export const ANNOTATION_DESCRIPTION_SUPPLY="ANNOTATION_DESCRIPTION_SUPPLY"
export const SCROLL_VALUE_FUNCTION="SCROLL_VALUE_FUNCTION"
export const VR_ENABLE_FUNCTION="VR_ENABLE_FUNCTION"
export const HANDLE_MAIN_CATEGORY_TITLE="HANDLE_MAIN_CATEGORY_TITLE"
export const HANDLE_SUB_CATEGORY_DATA="HANDLE_SUB_CATEGORY_DATA"
export const HANDLE_CURRENT_MAIN_CATEGORY_ID="HANDLE_CURRENT_MAIN_CATEGORY_ID"
export const HANDLE_FLOOR_PLAN_CLICK="HANDLE_FLOOR_PLAN_CLICK"
