import 'babel-polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './redux/store';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';


import loader from "./images/intro/loader.svg";

const Loader = () => (
    <img
        style={{position:"absolute",top:"48%",left:"48%",width:70,height:70}}
        className=""
        src={loader}
        alt="loader"
    />
)

const MainApp = () => (
    <Provider store={store}>
        <App/>
    </Provider>
)


ReactDOM.render(<MainApp />, document.getElementById('root'));
ReactDOM.render(<Loader />, document.getElementById('loader'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
