import React, {
  Component
  // ,lazy, Suspense
} from "react";
import { connect } from "react-redux";
import { sceneData } from "./redux/actions";
import ThreeMain from "./containers/three";
// import {apiurl,apitemp} from './api';
import HomePageC from "./containers/HomePage";

// const ThreeMain = lazy(() => import('./containers/three'));

// const basepath = "https://s3.ap-south-1.amazonaws.com/revlity/";

import "./webp-dect";
const { Modernizr } = window;

var baseurl = `https://newapirev.revlity.com/api/getproject`;
var bucketname = `revlity-production-player-assets`;
// if ((window.location.host === "www.revlity.com") || (window.location.host === "revlity.com")) {
//   baseurl = `https://www.revlity.com/api/getproject`;
//   bucketname = `revlityprodimages`
// } else {
//   baseurl = `https://dev.revlity.com/api/getproject`;
//   bucketname = `revlitydevimages`
// }


const pathh = window.location.pathname.split("/");
const urluuid = pathh[2];
class App extends Component {
  componentDidMount = () => {
    let size = 1024
    // if (window.screen.width <= 400) {
    //   size = 512
    // }
    fetch(`${baseurl}/${urluuid}.json`)
      .then(res => res.json())
      .then(res => {
        let palyerdata = JSON.parse(res.player_data);
        let categorydata = JSON.parse(res.category_data);

        let mainCategoryData = res.main_category ? JSON.parse(res.main_category) : res.main_category
        let catagorymain = categorydata.map((a, i) => ({
          // catagoryID: i+1,
          catagoryName: a.name,
          SceneID: a.imageuuid,
          thumbnailUrl: a.imagepath
        }));
        palyerdata.map((item, index) => {
          let sceneurl = `https://${bucketname}.s3.ap-south-1.amazonaws.com/${res.creator}/${res.title}/cubemap/${item.sceneId}/${size}`;

          Modernizr.on('webp', function (result) {
            if (result) {
              palyerdata[index].urls = {
                px: `${sceneurl}/px.webp`,
                nx: `${sceneurl}/nx.webp`,
                py: `${sceneurl}/py.webp`,
                ny: `${sceneurl}/ny.webp`,
                pz: `${sceneurl}/pz.webp`,
                nz: `${sceneurl}/nz.webp`,
              }
            } else {
              palyerdata[index].urls = {
                px: `${sceneurl}/px.jpg`,
                nx: `${sceneurl}/nx.jpg`,
                py: `${sceneurl}/py.jpg`,
                ny: `${sceneurl}/ny.jpg`,
                pz: `${sceneurl}/pz.jpg`,
                nz: `${sceneurl}/nz.jpg`,
              }
            }
          });
        })
        // console.log(catagorymain);

        catagorymain.map((item,index) => {
          catagorymain[index].thumbnailUrl = `https://${bucketname}.s3.ap-south-1.amazonaws.com/${res.creator}/${res.title}/category/${item.SceneID}.jpg`;
        })

        let mainCategory = []
        if (mainCategoryData && mainCategoryData.data) {
          mainCategoryData.data.map((item,index)=>{
            let subCategory = []
            item.subCategory.map((a,i)=>{
              subCategory[i] = {
                catagoryName: a.name,
                SceneID: a.imageuuid,
                thumbnailUrl: `https://${bucketname}.s3.ap-south-1.amazonaws.com/${res.creator}/${res.title}/category/${a.imageuuid}.jpg`
              }
            })
            mainCategory[index]={
              id: item.id,
              name: item.name,
              subCategory:subCategory
            }
          })
        }

        let main_category = res.main_category ? { data: mainCategory } : res.main_category
        let annotations = res.annotations ? JSON.parse(res.annotations) : res.annotations
        let aerial_data = res.aerial_data ? JSON.parse(res.aerial_data) : res.aerial_data
        let first_scene = res.first_scene ? JSON.parse(res.first_scene) : res.first_scene
        let brand_color = res.brand_color ? res.brand_color : "#ffce01"
        let autoplay = res.autoPlay ? res.autoPlay : false
        let data = {
          mainCategoryData:main_category,
          annotationData:annotations,
          aerialData:aerial_data,
          sceneItems: palyerdata,
          catagoryData: catagorymain,
          firstScene: first_scene,
          brandColor:brand_color,
          autoplay:autoplay,
          info: {
            creatorName: res.creator,
            companyName: res.title,
            companyLogoUrl: res.logo,
            bannerImgUrl: res.banner,
            creatorLogo: res.creator_logo
          }
        };

        document.title = "VR Tour | " + data.info.companyName;

        this.props.scenedataDispatch(data);
      });
  };

  // shouldComponentUpdate = (nextProps, nextState) => {
  //   let status =  ((nextProps.downloaded[0]===1)&&(nextProps.downloaded.length===1))
  //   return true
  // }

  render() {
    const {
      //  downloaded,
      removehome
    } = this.props;
    return (
      <div id="main-id">
        {!removehome && <HomePageC />}
        {removehome && (
          // <Suspense fallback={<div>Loading...</div>}>
          <ThreeMain />
          // </Suspense>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    removehome: state.removeHomeReducer,
    downloaded: state.downloadCompletedIdreducer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    scenedataDispatch: data => dispatch(sceneData(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
