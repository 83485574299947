import React, { Component } from 'react';
import { connect } from 'react-redux';
import HomePage from '../components/HomePage/HomePage';

class HomePageC extends Component {

  
  componentWillUnmount() {
    return true
  }
  
    
  render() {
    const { scenedata, showloader } = this.props
    return (
      <div>
        {
          scenedata.info && 
          <HomePage
          banner={scenedata.info.bannerImgUrl} 
          logo={scenedata.info.companyLogoUrl} 
          name={scenedata.info.companyName}
          creator={scenedata.info.creatorName}
          creatorLogo={scenedata.info.creatorLogo}
          firstScene={scenedata.sceneItems[0].sceneId}
          isLoading={showloader}
          />
        }
        </div>
    )
  }
};

function mapStateToProps(state) {
  return {
    showloader:state.showLoaderReducer,
    scenedata: state.sceneDataReducer
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // scenedataDispatch: data => dispatch(sceneData(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageC);