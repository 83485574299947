import {
    SCENE_DATA,
    DOWNLOAD_COMPLETE_SCENE_IDS,
    SHOW_LOADER,
    REMOVE_HOMEPAGE,
    ANNOTATION_VISIBLE,
    ANNOTATION_DESCRIPTION_SUPPLY,
    SCROLL_VALUE_FUNCTION,
    VR_ENABLE_FUNCTION,
    HANDLE_MAIN_CATEGORY_TITLE,
    HANDLE_SUB_CATEGORY_DATA,
    HANDLE_CURRENT_MAIN_CATEGORY_ID,
    HANDLE_FLOOR_PLAN_CLICK
} from './action-types';

export const sceneData = data => ({
    type: SCENE_DATA,
    data
})

export const downloadCompletedID = (id) => ({
    type: DOWNLOAD_COMPLETE_SCENE_IDS,
    id
})

export const showLoader = status => ({
    type: SHOW_LOADER,
    status
})

export const removeHome = status => ({
    type: REMOVE_HOMEPAGE,
    status
})

export const annotationVisible = val => ({
    type: ANNOTATION_VISIBLE,
    val
})

export const annotationDescriptionSupply = description => ({
    type: ANNOTATION_DESCRIPTION_SUPPLY,
    description
})
export const scrollValueFunction = val => ({
    type: SCROLL_VALUE_FUNCTION,
    val
})
export const vrEnableFunction = enable => ({
    type: VR_ENABLE_FUNCTION,
    enable
})
export const handleMainCategoryTitle = title => ({
    type: HANDLE_MAIN_CATEGORY_TITLE,
    title
})
export const handleSubCategoryData = data => ({
    type: HANDLE_SUB_CATEGORY_DATA,
    data
})
export const handleCurrentMainCtaegoryId = id => ({
    type: HANDLE_CURRENT_MAIN_CATEGORY_ID,
    id
})
export const handleFloorPlanClick = isEnabled => ({
    type: HANDLE_FLOOR_PLAN_CLICK,
    isEnabled
})

