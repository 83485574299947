import store from './redux/store';
import { downloadCompletedID, showLoader, removeHome } from './redux/actions';
// import {ImageLoader,FileLoader} from 'three';

// import _ from "lodash";
// import includes from "lodash/includes";
import pull from "lodash/pull";
import concat from "lodash/concat";
import pullAll from "lodash/pullAll";

import Axios from 'axios';
const CancelToken = Axios.CancelToken;

export var source = CancelToken.source();

export var downloadsInProgress = [];

var loaderelement = document.getElementById('loader');

var pendingIds;

export function pendingItems() {
    let storee = store.getState()
    pendingIds = storee.sceneDataReducer.sceneItems.map(a => a.sceneId)
    // for (let i = 0; i < storee.sceneDataReducer.sceneItems.length; i++) {
    //     let idd = storee.sceneDataReducer.sceneItems[i].sceneId
    //     pendingIds.push(idd)
    // }

}

export function downloadInitialLevel(sceneId, loader) {
    let storee = store.getState()
    let nexti = storee.sceneDataReducer.sceneItems[0].hotspots.map(y => y.sceneid);
    pullAll(pendingIds, nexti);
    pendingIds = concat(nexti, pendingIds);
    store.dispatch(showLoader(true));
    downloadImage(sceneId).then(x => {
        store.dispatch(removeHome(true));
        store.dispatch(showLoader(false));
    })
    //   for (let i = 0; i < nexti.length; i++) {
    //     const element = nexti[i];
    //     downloadImage(element).then(x=>{
    //       if (nexti.length===i+1) {
    //         store.dispatch(removeHome(true));
    //         store.dispatch(showLoader(false));
    //         downloadImage(pendingIds[0]);
    //       }
    //     });
    //   }

}

export function nextdownload() {
    if (pendingIds[0]) {
        downloadImage(pendingIds[0])
    }
}

function checkImageDownloaded(sceneId, loader, nextitems) {
    let storee = store.getState()
    let completed = storee.downloadCompletedIdreducer
    let status = completed.includes(sceneId);
    let firstid = storee.sceneDataReducer.sceneItems[0].sceneId;
    if (sceneId === firstid && !status) {

    }

    if (nextitems) {
        var level1 = []
        var level2 = []
        for (let i = 0; i < nextitems.length; i++) {
            let element = nextitems[i];
            let check1 = pendingIds.includes(element)
            if (check1) {
                pull(pendingIds, element);
                level1.push(element)
            }
            let next2 = storee.sceneDataReducer.sceneItems.find(x => x.sceneId === element).hotspots.map(y => y.sceneid);
            for (let i2 = 0; i2 < next2.length; i2++) {
                let element2 = next2[i2];
                let check2 = pendingIds.includes(element2)
                if (check2) {
                    pull(pendingIds, element2);
                    level2.push(element2)
                }
            }
        }
        pendingIds = concat(level1, level2, pendingIds);
    }

    return new Promise(function (resolve, reject) {
        if (!status) {
            if ((sceneId !== firstid) && loader) {
                loaderelement.style.display = "unset"
            }
            store.dispatch(showLoader(true));
            downloadImage(sceneId)
            .then(x => {
                if (x) {
                    resolve(true);
                }
            })
        } else {
            resolve(true);
        }
    })
}

function downloadSide(url) {
    return new Promise(function (resolve, reject) {
        Axios.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                downloadsInProgress.push(c)
            })
        })
            .then(x => {
                resolve(true)
            })
            .catch(function (thrown) {
                // console.log(thrown)
                reject("fail")
            });
    });
}


function downloadImage(sceneIDD) {
    let storee = store.getState()
    let scenee = storee.sceneDataReducer.sceneItems.find(n => n.sceneId === sceneIDD)
    // let scenee = storee.sceneDataReducer.sceneItems[indexofscene]
    let sceneid = scenee.sceneId
    let sceneurls = scenee.urls
    
    return new Promise(function (resolve, reject) {
        Promise.all([
            downloadSide(sceneurls.px),
            downloadSide(sceneurls.nx),
            downloadSide(sceneurls.nz),
            downloadSide(sceneurls.pz),
            downloadSide(sceneurls.ny),
            downloadSide(sceneurls.py),
        ])
            .then(x => {
                
                store.dispatch(downloadCompletedID(sceneid));

                pull(pendingIds, sceneid);

                // let index = pendingIds.indexOf(sceneid)
                // pendingIds.splice(index,1);

                loaderelement.style.display = "none"
                // store.dispatch(showLoader(false));
                // store.dispatch(removeHome(true));

                if (pendingIds[0]) {
                    checkImageDownloaded(pendingIds[0], false)
                }
                resolve(true);

            })
            .catch(n => {
                // if(pendingIds[0]){
                // checkImageDownloaded(pendingIds[0],false)
                // }
                // TODO FAIL IMAGE DOWNLOAD RELOAD SCENE
                // downloadImage(sceneid)
            })
    })

}


export default checkImageDownloaded;
