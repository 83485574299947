import {
  Group,
  Vector3,
  LineBasicMaterial,
  Line,
  PlaneBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  DoubleSide,
  FontLoader,
  Geometry,
  TextureLoader,
  BoxGeometry,
  BackSide,
  ShapeBufferGeometry
} from 'three';

var fontUrl ;
if(window.location.hostname!=="localhost"){
  fontUrl = '/vrplayerstatic/fonts/helvetiker_bold.typeface.json'
  // fontUrl = process.env.PUBLIC_URL+'/fonts/helvetiker_bold.typeface.json'
} else {
  fontUrl = process.env.PUBLIC_URL+'/fonts/helvetiker_bold.typeface.json'
}

class AerialScene  {
  constructor() {
  }
  loadFont=(data,index)=>{
    let group = new Group();
    let AerialTextLength=data.text.length
    return new Promise(function(resolve, reject) {
      var geometryBorder = new Geometry();
      geometryBorder.vertices.push(new Vector3( 0, 0, 0) );
      geometryBorder.vertices.push(new Vector3( 2, 0, 0) );
      geometryBorder.vertices.push(new Vector3( 2, 2, 0) )
      geometryBorder.vertices.push(new Vector3( 0, 2, 0) )
      geometryBorder.vertices.push(new Vector3( 0,0 , 0) );
      var materialBorder = new LineBasicMaterial( { color: 'white',linewidth:2.5,linejoin:'round',linecap:'round' } );
      let border = new Line( geometryBorder, materialBorder );
      border.name="border"
      group.add(border)

      var geometryPlane = new PlaneBufferGeometry( 1.5, 1.5,2,2);
      var materialPlane = new MeshBasicMaterial( {color: 'white', side: DoubleSide} );
      var plane = new Mesh( geometryPlane, materialPlane );
      plane.position.set(1,1,0)
      plane.name="plane"
      group.add(plane)

      group.position.set(data.position.x, data.position.y, data.position.z);
      group.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z);
      group.lookAt(0,0,0)

      let width = (AerialTextLength>6) ? (AerialTextLength/2)+2  : 5;
      let borderGap = (AerialTextLength>6) ? ((AerialTextLength/2)-3)/2 : 0

      var geometryInfoBorder = new PlaneBufferGeometry( width+0.3, 2);
      var materialInfoBorder = new MeshBasicMaterial( {color: 'white', side: DoubleSide} );

      let Infoborder = new Mesh( geometryInfoBorder, materialInfoBorder );
      Infoborder.position.set(4.9+borderGap,1,0.001)
      Infoborder.visible=false
      Infoborder.name="Infoborder"
      group.add(Infoborder)


      var geometryInfoPlane = new PlaneBufferGeometry( width, 1.7);
      var materialInfoPlane = new MeshBasicMaterial( {color: '#e0e0e0', side: DoubleSide} );
      var InfoPlane = new Mesh( geometryInfoPlane, materialInfoPlane );
      InfoPlane.position.set(4.9+borderGap,1,0.002)
      InfoPlane.visible=false
      InfoPlane.name="InfoPlane"
      group.add(InfoPlane)

      const AerialTextLoader = new FontLoader();
      // AerialTextLoader.load(process.env.PUBLIC_URL+'/fonts/helvetiker_bold.typeface.json',  ( font )=> {
      AerialTextLoader.load(fontUrl,  ( font )=> {
      var matLite = new MeshBasicMaterial( {
        color:  'black' ,
        transparent: true,
        opacity: 0.8,
        side: DoubleSide,
        flatShading: true
      } );
      var message = data.text;
      var shapes = font.generateShapes( message, 0.8);
      var geometryText = new ShapeBufferGeometry( shapes );
      geometryText.computeBoundingBox();
      let xMid = - 0.5 * ( geometryText.boundingBox.max.x - geometryText.boundingBox.min.x );
      geometryText.translate( xMid, 0, 0 );
      let AerialText = new Mesh( geometryText, matLite );
      AerialText.position.set(4.9+borderGap,0.7,0.003)
      AerialText.name="AerialText"
      AerialText.visible=false
      group.add( AerialText );
      group.sceneId=data.sceneId
      group.mainCategoryId=data.mainCategoryId
      group.scale.set(2,2,2)
      resolve(group)
    },
    ( xhr ) =>{
    		// console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
    },
     ( err ) =>{
    		console.log( 'An error happened',err );
    	} );
    })
  }

  loadImage=(url)=>{
    let that=this
    return new Promise(function(resolve, reject) {
      var cubeGeometry =  new BoxGeometry(100, 100, 100);
      Promise.all([
        that.loadTexture(url+'px.jpg'),
        that.loadTexture(url+'nx.jpg'),
        that.loadTexture(url+'py.jpg'),
        that.loadTexture(url+'ny.jpg'),
        that.loadTexture(url+'pz.jpg'),
        that.loadTexture(url+'nz.jpg')
      ]).then((materials)=>{
        var cubeMesh = new Mesh(cubeGeometry, materials);
        resolve(cubeMesh)
      })
  })
  }

  loadTexture(url) {
    let loader=new TextureLoader
    return new Promise(function(resolve, reject) {
      loader.load(url, function(texture) {
        let text = new MeshBasicMaterial({
          map: texture,
          side: BackSide,
          transparent: true,
          opacity: 1,
          depthWrite: false
        });
        resolve(text);
      });
    });
  }
}

export default AerialScene
