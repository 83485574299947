import React, { Component } from 'react'
import "./mainCategory.css"
import mainCategoryIconSVG from './icons/floor.svg'
import { connect } from "react-redux"
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';


class MainCategoryTest extends Component {

    state = {
        dropdownIsActive: false,
        activeMainCategoryId: 1,
        activeMainCategoryName: null,
        activeSubcategorySceneID: null,
        mainCategoryRefs: []
    }


    componentDidMount() {
        let mainCategoryList = this.props.mainCategory
        let reff = mainCategoryList.map(() => React.createRef())

        this.setState({
            mainCategoryRefs: reff,
            activeSubcategorySceneID: this.props.firstScene.sceneId,
            activeMainCategoryId: "1",
            activeMainCategoryName: this.props.mainCategory[0].name
        })

        let root = document.documentElement;
        root.style.setProperty('--scroll-color', this.props.brandColor);

        this.handleBottomNavStyle()
        window.addEventListener('resize', this.handleBottomNavStyle)

    }

    handleBottomNavStyle = () => {
        let root = document.documentElement;

        if (window.innerWidth < 1080 && (window.innerHeight < window.innerWidth)) {
            root.style.setProperty('--bottom-nav-right', "190px")
            root.style.setProperty('--bottom-nav-bottom', "30px")
            document.querySelector('.btn-group').style.display = "block"
        } if (window.innerWidth > 1080 && (window.innerHeight < window.innerWidth)) {
            root.style.setProperty('--bottom-nav-right', "300px")
            root.style.setProperty('--bottom-nav-bottom', "30px")
            document.querySelector('.btn-group').style.display = "block"
        } if (window.innerWidth < 1080 && (window.innerHeight > window.innerWidth)) {
            root.style.setProperty('--bottom-nav-right', "24px")
            root.style.setProperty('--bottom-nav-bottom', "133px")
            document.querySelector('.btn-group').style.display = "flex"
        }

    }



    handleMainCategoryClick = (e) => {
        e.preventDefault()
        this.setState({ dropdownIsActive: true })
        document.addEventListener('click', this.hideDropdownMenu, false);
        document.getElementById('dropdown-content').addEventListener('touchend', this.stopPropagation);

        document.addEventListener('touchend', this.hideDropdownMenu, false);

    }

    stopPropagation = (e) => {
        e.stopPropagation();
    }

    hideDropdownMenu = () => {
        this.setState({ dropdownIsActive: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
            document.removeEventListener('touchend', this.hideDropdownMenu);
            document.getElementById('dropdown-content').removeEventListener('touchend', this.stopPropagation);

        });
    }

    handleSubCategoryClick = (id, mainID, mainCategoryName) => {
        this.props.handleSceneChange(id, false);
        this.setState({ activeSubcategorySceneID: id, activeMainCategoryId: mainID, activeMainCategoryName: mainCategoryName })
    }

    handleMainCategorySelection = (id, index) => {
        const { mainCategory } = this.props
        const selectedCategory = mainCategory[index]
        const selectedCategoryFirstSceneId = selectedCategory.subCategory[0].SceneID
        this.setState(
            {
                ...this.state,
                activeMainCategoryId: id,
                activeMainCategoryName: selectedCategory.name,
                activeSubcategorySceneID: selectedCategoryFirstSceneId
            })
        this.props.handleSceneChange(selectedCategoryFirstSceneId, false)
        this.state.mainCategoryRefs[index].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }

    render() {
        const { mainCategory, brandColor, isAutoplayEnabled } = this.props
        return (
            <div className="category-container">

                <div onClick={this.handleMainCategoryClick} style={{ left: isAutoplayEnabled? '50px': '10px'}} className="layer-btn">
                    <svg className="layer-svg" viewBox="0 0 32 32" aria-hidden="true" focusable="false" role="img">
                        <title>Layers</title>
                        <path stroke="none" d="M29 13.91l-1.81-1 1.9-1.18a2 2 0 00-.09-3.4L17 1.76a2.13 2.13 0 00-2 0L3 8.33a2 2 0 000 3.4l1.9 1.18-1.9 1a2 2 0 000 3.4l1.82 1.13-1.82.95a2 2 0 000 3.4l11.94 7.39a2.09 2.09 0 002.22 0l11.94-7.39a2 2 0 00-.09-3.4l-1.73-.95 1.82-1.13a2 2 0 00-.1-3.4zM4.06 10.06L16 3.49l11.94 6.57-2.81 1.74-2 1.21L16 17.45 8.83 13l-2-1.21zm23.88 11.06L16 28.51 4.06 21.12l2.67-1.47 8.16 5.05a2.09 2.09 0 002.22 0l8.16-5.05zM16 23L4.06 15.64l2.75-1.52 8.08 5a2.09 2.09 0 002.22 0l8.08-5 2.75 1.52z"></path>
                    </svg>
                    <span>{this.state.activeMainCategoryName}</span>
                </div>

                <div className="dropdown">

                    <div id="category-btn" className="category-btn" onClick={this.handleMainCategoryClick}>
                        <a style={{ color: brandColor }} className="dropbtn">{this.state.activeMainCategoryName}</a>

                        <svg className="icon">
                            <g fill="none" strokeLinecap="round">
                                <path stroke={brandColor} strokeWidth="2" d="M28.657 12L23 17.657 17.343 12" />
                                <path d="M1 1v26" stroke="#D1D1D5" />
                            </g>
                        </svg>
                    </div>



                    <div style={{ display: this.state.dropdownIsActive ? 'block' : 'none' }} id="dropdown-content" className="dropdown-content">

                        <div className="category-title">
                            <img src={mainCategoryIconSVG} />
                            <span>Places</span>
                        </div>

                        <SimpleBar className="maincategory-content-slider" style={{ width: "200px" }}>
                            <div className="main-category-items">

                                {mainCategory.map((item, index) => {
                                    let className = 'item';
                                    if (this.state.activeMainCategoryId === item.id) {
                                        className += ' active';
                                    }
                                    return (
                                        <div key={item.id}
                                            onClick={() => { this.handleMainCategorySelection(item.id, index) }}
                                            className={className}>
                                            <div className="tick" />
                                            {/* <div className="gap" /> */}
                                            {item.name}
                                        </div>
                                    )
                                })}

                            </div>
                        </SimpleBar>

                    </div>
                </div>

                <SimpleBar className="simple-main-cat-wrapper" style={{ maxHeight: "calc(100% - 80px)" }}>
                    <div className="category">

                        {
                            mainCategory.map((item, index) => {
                                let mainCategoryID = item.id
                                let mainCategoryName = item.name
                                let className = 'sub-category-section';
                                if (this.state.activeMainCategoryId === item.id) {
                                    className += ' active';
                                }
                                return (
                                    <div id={item.id} ref={this.state.mainCategoryRefs[index]} key={item.id} className={className}>
                                        <div className="heading">
                                            <span>{item.name}</span>
                                        </div>
                                        <div className="thumbnails">
                                            {
                                                item.subCategory.map((subCategory) => {
                                                    let className = 'items';
                                                    let style = {}
                                                    if (this.state.activeSubcategorySceneID === subCategory.SceneID) {
                                                        className += ' active';
                                                        style = { borderColor: brandColor }
                                                    }
                                                    return (
                                                        <div onClick={() => { this.handleSubCategoryClick(subCategory.SceneID, mainCategoryID, mainCategoryName) }}
                                                            key={subCategory.SceneID}
                                                            className={className}
                                                            style={style}
                                                        >

                                                            <img
                                                                src={subCategory.thumbnailUrl} />
                                                            <div className="title">
                                                                <label>{subCategory.catagoryName}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </SimpleBar>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        mainCategory: state.sceneDataReducer.mainCategoryData.data,
        firstScene: state.sceneDataReducer.firstScene
    };
}

export default connect(mapStateToProps)(MainCategoryTest);
