import { combineReducers } from 'redux';
import {
  SCENE_DATA,
  DOWNLOAD_COMPLETE_SCENE_IDS,
  SHOW_LOADER,
  REMOVE_HOMEPAGE,
  ANNOTATION_VISIBLE,
  ANNOTATION_DESCRIPTION_SUPPLY,
  SCROLL_VALUE_FUNCTION,
  VR_ENABLE_FUNCTION,
  HANDLE_MAIN_CATEGORY_TITLE,
  HANDLE_SUB_CATEGORY_DATA,
  HANDLE_CURRENT_MAIN_CATEGORY_ID,
  HANDLE_FLOOR_PLAN_CLICK
} from './action-types';

function sceneDataReducer(state = [], action) {
  switch (action.type) {
    case SCENE_DATA:
      return action.data
    default:
      return state
  }
}

function showLoaderReducer(state = false, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return action.status
    default:
      return state
  }
}

function removeHomeReducer(state = false, action) {
  switch (action.type) {
    case REMOVE_HOMEPAGE:
      return action.status
    default:
      return state
  }
}

function downloadCompletedIdreducer(state = [], action) {
  switch (action.type) {
    case DOWNLOAD_COMPLETE_SCENE_IDS:
      return [...state,action.id]
    default:
      return state
  }
}
function annotationHandleReducer(state = {isAnnotationVisble:false,body:'',title:'',url:null}, action) {
  switch (action.type) {
    case ANNOTATION_VISIBLE:
      return {...state,isAnnotationVisble:action.val}
    case ANNOTATION_DESCRIPTION_SUPPLY:
      return {...state,body:action.description.body,title:action.description.title,url:action.description.url};
    default:
      return state
  }
}

function scrollValueReducer(state = {scrollValue:0,isVr:false}, action) {
  switch (action.type) {
    case SCROLL_VALUE_FUNCTION:
      return {...state,scrollValue:`translateX(${action.val*228}px)`}
    case VR_ENABLE_FUNCTION:
      return {...state,isVr:action.enable}
    default:
      return state
  }
}

function mainCategoryReducer(state = {
  title:'category',
  subCategoryData:[],
  currentMainCategoryId:0,
  }, action) {
  switch (action.type) {
    case HANDLE_MAIN_CATEGORY_TITLE:
      return {...state,title:action.title}
    case HANDLE_SUB_CATEGORY_DATA:
      return {...state,subCategoryData:action.data};
    case HANDLE_CURRENT_MAIN_CATEGORY_ID:
      return {...state,currentMainCategoryId:action.id};
    default:
      return state
  }
}

function handleFloorPlanClickReducer(state = {isEnabled: false}, action) {
  switch (action.type) {
    case HANDLE_FLOOR_PLAN_CLICK:
      return {...state, isEnabled: action.isEnabled}
    default:
      return state
  }
}

const reducer = combineReducers({
    sceneDataReducer,
    downloadCompletedIdreducer,
    showLoaderReducer,
    removeHomeReducer,
    annotationHandleReducer,
    scrollValueReducer,
    mainCategoryReducer,
    handleFloorPlanClickReducer
})

export default reducer;
