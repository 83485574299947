import {
  Object3D,
  TextureLoader,
  MeshBasicMaterial,
  Mesh,
  PlaneGeometry,
  BoxGeometry,
  BackSide,
  DoubleSide,
  Group,
  Vector3,
  Color,
  ShapeBufferGeometry
} from 'three';
import SVGLoader from  'three-svg-loader';
import Annotation from './annotation.js'
import ArrowHotspot from "../images/hotspot-arrow.svg";
import TextSprite from 'three.textsprite';

import ThreeScene from "./scene";
import checkImageDownloaded ,{nextdownload,source,downloadsInProgress} from "../download-manager";
import AerialScene from './aerialScene.js'
import store from '../redux/store';
import {
  aerilaViewEnableFunction,
  scrollValueFunction,
  annotationVisible,
  annotationDescriptionSupply,
  handleMainCategoryTitle,
  handleSubCategoryData,
  handleCurrentMainCtaegoryId
} from '../redux/actions';


const TWEEN = require('@tweenjs/tween.js');
const rad2deg = 180 / Math.PI

var group = new Group();

svgLoaderFunc();
function svgLoaderFunc(){
  var svgLoader1 = new SVGLoader();
  svgLoader1.load( ArrowHotspot, svgLoaderGroup )
}

function svgLoaderGroup ( paths ) {
  for ( var i = 0; i < paths.length; i ++ ) {
    var path = paths[ i ];
    var material = new MeshBasicMaterial({
      side: DoubleSide,
      color:path.color
    });
    var shapes = path.toShapes( true );
      let z
    if (i===0) {
      z=0
    }
    else {z=0.15}
    for ( var j = 0; j < shapes.length; j ++ ) {
      var shape = shapes[ j ];
      var geometry = new ShapeBufferGeometry( shape );
      var mesh = new Mesh( geometry, material );
      mesh.scale.set(-0.02,0.02,0.02)
      mesh.position.set(5,0,z)
      group.add( mesh );
    }
  }
  group.name='hotspot'
}


class CubeAdd extends ThreeScene {
  constructor(sceneDataa,annotationData,mainCategoryData,brandColor,disableAutoplayButton) {
    super();
    this.sceneData = sceneDataa;
    this.mainCategoryData=mainCategoryData
    this.disableAutoplayButton = disableAutoplayButton
    this.animate();
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;

    this.currentSceneId=sceneDataa[0].sceneId

    this.aerialMesh=new AerialScene()

    this.loader = new TextureLoader();
    this.group = group
    this.selectedObject=null
    this.selectedVrObject=null

    this.storee =  store.getState()
    // this.currentMainCategoryId = 0

    var hotspotPlaneMesh = new MeshBasicMaterial({
      side: DoubleSide,
      transparent: true,
      opacity: 0,
      alphaTest: 0.9
    });
    this.hotspotPlain = new Mesh(
      new PlaneGeometry(10, 5),
      hotspotPlaneMesh
    );
    this.hotspotPlain.rotation.x=90
    this.hotspotPlain.position.z=-1.3
    this.group.add(this.hotspotPlain)


    this.tempobj = new Object3D();
    this.tempobj.name="tempobj"
    this._scene.add(this.tempobj);

    this.annotation=new Annotation()
    this.annotations.name='annotations'

    var planemesh = new MeshBasicMaterial({
      side: DoubleSide,
      transparent: true,
      opacity: 0,
      alphaTest: 0.9
    });
    this.planeMeshMain = new Mesh(
      new PlaneGeometry(100, 100),
      planemesh
    );

    this.hotspotAnglesId = [];

    this.container.addEventListener(
      "mousemove",
      this.onDocumentMouseMove.bind(this),
      false
    );

    this.clickOnHotspots = this.clickOnHotspots.bind(this);

    this.mouseupHotspot = this.mouseupHotspot.bind(this);
    this.mousedownHotspot = this.mousedownHotspot.bind(this);

    this.touchstartHotspot = this.touchstartHotspot.bind(this);
    this.touchendHotsport = this.touchendHotsport.bind(this);

    this.eventsclick = [];
    this.eventstouch = [];

    this.autoplayenabled = false
    this.autoplayCircle = document.querySelector('.progress-ring__circle');
    if (this.autoplayCircle) {
      this.circumference = this.autoplayCircle.r.baseVal.value * 2 * Math.PI;
      this.autoplayCircle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
      this.autoplayCircle.style.strokeDashoffset = `${this.circumference}`;
    }

    this.autoplayHighlightText = []
    let autoplayTextColor = new Color('white')
    this.autoplayTextPosZ = -5
    this.autoplayTextPosZAfterAnim = -3
    if (window.screen.width <= 400) {
      this.autoplayTextPosZ = -10
      this.autoplayTextPosZAfterAnim = -6
    }
    if (this.mainCategoryData && this.mainCategoryData.data) {

      this.generateAutoplayText().then((autoplayHighlightText)=>{
        this.mainCategoryData.data.map((item)=>{
          this.autoplayHighlightText[item.id] = autoplayHighlightText.clone()
          this.autoplayHighlightText[item.id].material.map._lines = [item.name]
          this.autoplayHighlightText[item.id].material.map._textWidthInPixels = 150;
          this.autoplayHighlightText[item.id].material.map._fontFamily = "IBM Plex Serif";
          this.autoplayHighlightText[item.id].material.map._fontStyle = "Italic";
          this.autoplayHighlightText[item.id].material.map._fontWeight = "500";
          // this.autoplayHighlightText[item.id].material.map._strokeStyle = strokecolor ;
          // this.autoplayHighlightText[item.id].material.map._strokeWidth = 0.05;
          this.autoplayHighlightText[item.id].material.color = autoplayTextColor
          this.autoplayHighlightText[item.id].position.set(0,0,this.autoplayTextPosZ)
        })
      })
    }
    // this.generateCube(1,1)

    // Generate Cube By passing sceneID  and opacity
  }

  generateAutoplayText = () => {
    return new Promise((resolve,reject) => {
      let autoplayHighlightText = new TextSprite({
        material: {
          fog: true,
        },
        redrawInterval: 250,
        textSize: 15,
        texture: {
          fontFamily: '"IBM Plex Serif", "Times New Roman", Times, serif ',
          text: '',
        },
      })
      resolve(autoplayHighlightText)
    });
  }
  loadTexture(url, transpancy) {
    let that = this;
    return new Promise(function(resolve, reject) {
      that.loader.load(url, function(texture) {
        let text = new MeshBasicMaterial({
          map: texture,
          side: BackSide,
          transparent: true,
          opacity: transpancy,
          depthWrite: false
        });
        resolve(text);
      });
    });
  }

  // Here we Generate Cube by sceneid and opcatiy
  generateCube=(sceneid, transpancy, stopscript) =>{
    // console.log('scene',this._scene);
    // console.log('sceneid',sceneid);
    this.currentSceneId=sceneid

    this.disposeAerialspot()  // remove aerial spots if exist
    this.disposeAnnotations()  //remove annotation of prev cube

    var cubedata = this.sceneData.find(n => n.sceneId === sceneid);
    if (cubedata.Annotations) {
      this.addAnnotations(cubedata.Annotations)   // add annotation
    }
    if (cubedata.story) {
      this.story = cubedata.story
    }
    else {
      this.story = null
    }
    // var loader = new THREE.TextureLoader();
    var urls = cubedata.urls;
    var cubeGeometry = new BoxGeometry(100, 100, 100);
    let that = this;
    if(stopscript){
      downloadsInProgress.map(x=>{
        x();
      })
    }
    this.cubeRotationBalance = cubedata.rotation.y ;

    return new Promise(function(resolve, reject) {
      Promise.all([
        that.loadTexture(urls.px, transpancy),
        that.loadTexture(urls.nx, transpancy),
        that.loadTexture(urls.py, transpancy),
        that.loadTexture(urls.ny, transpancy),
        that.loadTexture(urls.pz, transpancy),
        that.loadTexture(urls.nz, transpancy)
      ]).then(materials => {
        var cubeMesh = new Mesh(cubeGeometry, materials);
        cubeMesh.scale.set(-1, 1, 1);
        cubeMesh.position.set(
          cubedata.position.x,
          cubedata.position.y,
          cubedata.position.z
        );
        cubeMesh.rotation.set(
          cubedata.rotation.x,
          cubedata.rotation.y,
          cubedata.rotation.z
        );
        // console.log(cubedata.hotspots);
        let hotspots = cubedata.hotspots;
         that.hotspotempty = new Object3D();
        that.hotspotempty.name='listOfHotspot'
        that.hotspotAnglesId = [];
        for (let index = 0; index < hotspots.length; index++) {
          let hotspotmesh = that.group.clone();
          let hotspot = hotspots[index];
          hotspotmesh.rotation.set(
            hotspot.rotation.x,
            hotspot.rotation.y,
            hotspot.rotation.z
          );
          hotspotmesh.position.set(
            hotspot.arrowposs.x,
            -15,
            hotspot.arrowposs.z
          );
          // let rotval = THREE.Math.radToDeg(hotspot.rotfromcam);
          let rotval = hotspot.rotfromcam * rad2deg
          that.hotspotAnglesId.push({
            sceneid: hotspot.sceneid,
            angle: rotval
          });

          hotspotmesh.userData = hotspot.sceneid;
          that.hotspotempty.scale.set(-1, 1, 1);

          hotspotmesh.lookAt(hotspot.position.x, 500, hotspot.position.z);
          that.hotspotempty.add(hotspotmesh);
        }
        cubeMesh.add(that.hotspotempty);
        var planeempty = new Object3D();

        // left
        var p1 = that.planeMeshMain.clone();
        p1.position.x = 50;
        p1.rotation.y = 1.5708;
        p1.userData = "left";
        planeempty.add(p1);

        // right
        var p2 = that.planeMeshMain.clone();
        p2.position.x = -50;
        p2.rotation.y = -1.5708;
        p2.userData = "right";
        planeempty.add(p2);

        // front
        var p3 = that.planeMeshMain.clone();
        p3.position.z = -50;
        p3.rotation.y = 3.14159;
        p3.userData = "front";
        planeempty.add(p3);

        // back
        var p4 = that.planeMeshMain.clone();
        p4.position.z = 50;
        p4.userData = "back";
        planeempty.add(p4);

        // bottom
        var p5 = that.planeMeshMain.clone();
        p5.position.y = -50;
        p5.rotation.x = 1.5708;
        p5.userData = "bottom";
        planeempty.add(p5);

        cubeMesh.add(planeempty);
        cubeMesh.add(that.annotations)  // annotations added to child of cubemesh
        cubeMesh.add(that.aerialPoints)
        that._scene.add(cubeMesh);
        let aerialPoints = cubedata.aerialSpots
        if (aerialPoints) {
          aerialPoints.map((item,index)=>{
            that.aerialMesh.loadFont(item,index).then((y)=>{
              y.name="aerial-spot"
              that.aerialPoints.add(y)
            })
          })
        }

        that.container.addEventListener(
          "mouseup", that.mouseupHotspot, false
        );

        that.container.addEventListener(
          "mousedown", that.mousedownHotspot, false
        );

        that.container.addEventListener(
          "touchstart", that.touchstartHotspot, false
        );
        that.container.addEventListener("touchmove", that.touchmoveHotspot, false);

        that.container.addEventListener(
          "touchend", that.touchendHotsport, false
        );

        // if (store.getState().mainCategoryReducer.autoplay) {
        //   that.autoplayHandler()
        // }
        resolve(true);
      });
    });
  }

  faceData = {
    face1: -45,
    face2: 45,
    face3: 135,
    face4: 225
  };

  findAngle(uvx, uvy, cubeFace) {
    // var x = uvx;
    // var y = uvy;

    var angle = uvx * 90;

    var angleInDegrees;
    if (cubeFace === "front"){
      angleInDegrees = angle + this.faceData.face1;
    } else if (cubeFace === "right"){
      angleInDegrees = angle + this.faceData.face2;
    } else if (cubeFace === "back"){
      angleInDegrees = angle + this.faceData.face3;
    } else if (cubeFace === "left"){
      angleInDegrees = angle + this.faceData.face4;
    }

    // switch (cubeFace) {
    //   case (cubeFace = "front"):
    //     var angleInDegrees = angle + this.faceData.face1;
    //     break;
    //   case (cubeFace = "right"):
    //     var angleInDegrees = angle + this.faceData.face2;
    //     break;
    //   case (cubeFace = "back"):
    //     var angleInDegrees = angle + this.faceData.face3;
    //     break;
    //   case (cubeFace = "left"):
    //     var angleInDegrees = angle + this.faceData.face4;
    //     break;
    // }
    if (angleInDegrees < 180) {
      angleInDegrees = -angleInDegrees;
    } else if (angleInDegrees > 180) {
      angleInDegrees = -angleInDegrees + 360;
    }
    return angleInDegrees;
  }

  indexOfClosest(nums, target) {
    let closest = 30;
    let index = null;

    nums.forEach((num, i) => {
      let dist = Math.abs(target - num.angle);

      if (dist < closest) {
        index = num;
        closest = dist;
      }
    });

    return index;
  }

  mousedownHotspot(event){
    this.eventsclick = [
      event.clientX,
      event.clientY
    ]
  }

  touchstartHotspot(event){
    if (event.touches.length === 2) {
      var dx = event.touches[ 0 ].pageX - event.touches[ 1 ].pageX;
      var dy = event.touches[ 0 ].pageY - event.touches[ 1 ].pageY;
      this._touchZoomDistanceEnd = this._touchZoomDistanceStart = Math.sqrt( dx * dx + dy * dy );
    }
    this.eventstouch = [
      event.changedTouches[0].pageX,
      event.changedTouches[0].pageY
    ]
  }

  touchmoveHotspot=(event)=>{
    if (event.touches.length === 2) {
     var dx = event.touches[ 0 ].pageX - event.touches[ 1 ].pageX;
     var dy = event.touches[ 0 ].pageY - event.touches[ 1 ].pageY;
     this._touchZoomDistanceEnd = Math.sqrt( dx * dx + dy * dy );
     var factor = this._touchZoomDistanceStart /this._touchZoomDistanceEnd;
     this._touchZoomDistanceStart = this._touchZoomDistanceEnd;
     if (factor > 1 && this._camera.fov!==120) { this._camera.fov += 1.5}
     if (factor < 1 && this._camera.fov!==60) { this._camera.fov -= 1.5 }
     this._camera.updateProjectionMatrix()
   }
  }

  mouseupHotspot(event){
    let eve = [
      event.clientX,
      event.clientY
    ]

    if ((this.eventsclick[0] >= eve[0]-5 && this.eventsclick[0] <= eve[0]+5)&&
      (
        this.eventsclick[1] >= eve[1]-5 && this.eventsclick[1] <= eve[1]+5
      )
      ){
        this.clickOnHotspots(event);
      }

    // if((this.eventsclick[0] === eve[0])&&(this.eventsclick[1] === eve[1])){
    //   this.clickOnHotspots(event);
    // }
  }

  touchendHotsport(event){
      this._touchZoomDistanceStart = this._touchZoomDistanceEnd = 0;
      let eve = [
        event.changedTouches[0].pageX,
        event.changedTouches[0].pageY
      ]

      if ((this.eventstouch[0] >= eve[0]-4 && this.eventstouch[0] <= eve[0]+4)&&
      (
        this.eventstouch[1] >= eve[1]-4 && this.eventstouch[1] <= eve[1]+4
      )
      ){
        this.clickOnHotspots(event);
      }
      // if((this.eventstouch[0] === eve[0])&&(this.eventstouch[1] === eve[1])){
      //   this.clickOnHotspots(event);
      // }
    }

  onDocumentMouseMove( event ) {
    if (event) {
      event.preventDefault();
      this.mouse.x = (event.clientX / this.container.clientWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / this.container.clientHeight) * 2 + 1;
    }
  }

  rotatationController = (azimuthal,polar, time) => {
    return new Promise((resolve) =>
    this.autoplayTimer1 =  setTimeout(() => {
      let correctedAzimuthal = (azimuthal+this.cubeRotationBalance)%(2*Math.PI)
      if (this.autoplayenabled) {
        this._controls.rotateTo(correctedAzimuthal, polar, true)
      }
      resolve(true)
    },time))
  }

  autoplayHandler = async (val) => {
    // store.dispatch(autoplayEnableFunction(true))
    let time = val || 2200;
    this.autoplayenabled = true;
    this._controls.dampingFactor = 0.015 ;

    document.getElementById("progress-ring").classList.add("progress-ring__circle");
    document.getElementById("progress-ring").classList.remove("progress-ring__circle_reset")

    if (this.story && this.story.autoplayData.length ) {
      let length = this.story.autoplayData.length ;
      for (var i = 0; i < length ; i++) {
        if (this.autoplayenabled) {
          await this.rotatationController(this.story.autoplayData[i].azimuthal,this.story.autoplayData[i].polar,time)
          this.handlePercentOfAutoplaytimer((i+1)/length)
          time = 2200 ;
        }
        else {
          return ;
        }
      }
      if (this.story && this.story.target) {
        this.autoplayTimer2 = setTimeout(() => {
          if (this.autoplayenabled) {
            this.removeHotspots(this.story.target.nextSceneId,0,this.story.target.animation)
            document.getElementById("progress-ring").classList.remove("progress-ring__circle");
            document.getElementById("progress-ring").classList.add("progress-ring__circle_reset")
            this.autoplayCircle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
            this.autoplayCircle.style.strokeDashoffset = `${this.circumference}`;
          }
        },4000)
      }
      else if(this.mainCategoryData && this.mainCategoryData.data.length){
        this.nextCategoryAutoplay()
      }
      else {
        setTimeout(()=>{
          this.stopAutoplay()
          this.disableAutoplayButton()
        },2500)
      }
    }
    else if(this.mainCategoryData && this.mainCategoryData.data.length){
      this.nextCategoryAutoplay(time)
    }
    else {
      setTimeout(()=>{
        this.stopAutoplay()
        this.disableAutoplayButton()
      },2500)
    }
  }

  nextCategoryAutoplay = (val) => {
    let time =  val || 2000
    this.autoplayTimer3 = setTimeout(() => {
      if (this.autoplayenabled) {
        let t = store.getState().mainCategoryReducer.currentMainCategoryId ;
        let currentMainCategoryId = (t < this.mainCategoryData.data.length-1 ) ? t + 1 : 0;
        let id = this.mainCategoryData.data[currentMainCategoryId].subCategory[0].SceneID
        store.dispatch(handleSubCategoryData(this.mainCategoryData.data[currentMainCategoryId].subCategory))
        store.dispatch(handleMainCategoryTitle(this.mainCategoryData.data[currentMainCategoryId].name))
        if (currentMainCategoryId < this.mainCategoryData.data.length-1) {
          store.dispatch(handleCurrentMainCtaegoryId(currentMainCategoryId ));
        }
        else {
          store.dispatch(handleCurrentMainCtaegoryId(-1));
        }
        this.removeHotspots(id,0,false)
        .then(()=>{
          // autoplay Highlight Text tweening
          this._camera.add(this.autoplayHighlightText[currentMainCategoryId+1])
          setTimeout(()=>{
            new TWEEN.Tween(this.autoplayHighlightText[currentMainCategoryId+1].material)
            .to({opacity:0}, 1000)
            .start();
            new TWEEN.Tween(this.autoplayHighlightText[currentMainCategoryId+1].position)
            .to({x:0,y:0,z:this.autoplayTextPosZAfterAnim}, 1000)
            .onComplete(()=>{
              this._camera.remove(this._camera.children[1])
              this.autoplayHighlightText[currentMainCategoryId+1].position.set(0,0,this.autoplayTextPosZ)
              this.autoplayHighlightText[currentMainCategoryId+1].material.opacity = 1
            })
            .start();
          },1000)
        })

        // reset autoplay loading
        document.getElementById("progress-ring").classList.remove("progress-ring__circle");
        document.getElementById("progress-ring").classList.add("progress-ring__circle_reset")
        this.autoplayCircle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
        this.autoplayCircle.style.strokeDashoffset = `${this.circumference}`;

        // fix indicator and scrolling in scrollbar at bottom
        store.dispatch(scrollValueFunction(0));
        // document.getElementsByClassName("scrollable-container")[0].scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: "smooth"
        // })
      }
    },time)
  }

  handlePercentOfAutoplaytimer = (percent) => {
    let offset = this.circumference - percent  * this.circumference;
    this.autoplayCircle.style.strokeDashoffset = offset;
  }
  stopAutoplay=()=>{
    this.autoplayenabled = false
    this._controls.dampingFactor = 0.05 ;
    this.autoplayCircle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
    this.autoplayCircle.style.strokeDashoffset = `${this.circumference}`;
    clearTimeout(this.autoplayTimer1)
    clearTimeout(this.autoplayTimer2)
    clearTimeout(this.autoplayTimer3)
  }



  // Hotspot enlarge and crosshair Compress when hotspot touches Rectile
  recticleOnItem=(item)=>{
    new TWEEN.Tween(item.parent.scale)
      .to({x: 1.4, y: 1.4, z: 1.4}, 1000)
      .onStart(() => { this.isUp=true  })
      .start();
    new TWEEN.Tween(this.crosshair.scale)
      .to({x: 0, y: 0, z: 0}, 1000)
      .onStart(() => { this.transitionAllow=true })
      .onComplete(() => {
        this.isUp=false
        if (this.transitionAllow && this._scene.children[2].children[0] &&  this._scene.children[2].children[1])
        {
          var intersectsPlane = this.vrRaycaster.intersectObjects( this._scene.children[2].children[1].children );
          if (intersectsPlane.length) {
            var ang = this.findAngle(
              intersectsPlane[0].uv.x,
              intersectsPlane[0].uv.y,
              intersectsPlane[0].object.userData
            );
            let targert = this.indexOfClosest(this.hotspotAnglesId, ang);
            if (targert) {
              this.removeHotspots(targert.sceneid,0,true)
            }
          }
        }
      })
      .start()
  }

  // Hotspot Compress and crosshair enlarge when hotspot leaves Rectile
  recticleOffItem=(item)=>{
    new TWEEN.Tween(item.parent.scale)
      .to({x: 1, y: 1, z: 1}, 1000)
      .onStart(() => { this.isDown=true })
      .start();
    new TWEEN.Tween(this.crosshair.scale)
      .to({x: 1, y: 1, z: 1}, 1000)
      .onComplete(() => { this.isDown=false })
      .start();
  }

  recticleOnAerialSpotTransition = () => {
    new TWEEN.Tween(this.crosshair.scale)
      .to({x: 0, y: 0, z: 0}, 1000)
      .onStart(() => {
         this.upAerialSpot=true ;
         this.aerialSpotTransitionallow = true
       })
      .onComplete(() => {
        this.upAerialSpot = false
        if (this.selectedObject && this.aerialSpotTransitionallow) {
          let selectedObject = this.selectedObject.parent
          let key = 0
          if (this.mainCategoryData && this.mainCategoryData.data.length) {
            let [category] = this.mainCategoryData.data.filter(obj => {
              return obj.id === selectedObject.mainCategoryId;
            })
            store.dispatch(handleSubCategoryData(category.subCategory))
            store.dispatch(handleMainCategoryTitle(category.name))
            store.dispatch(handleCurrentMainCtaegoryId(category.id-1));
            key = category.subCategory.findIndex(obj => obj.SceneID === selectedObject.sceneId)
          }
          //fix indicator and scrolling in scrollbar at bottom
          store.dispatch(scrollValueFunction(key));
          let scrollableContainer = document.getElementsByClassName("scrollable-container")[0].scrollTo({
            top: 0,
            left: key*180,
            behavior: "smooth"
          })

          this.removeHotspots(selectedObject.sceneId,0,false);
          this.recticleOffAerialSpotTransition()
        }
       })
      .start();
  }
  recticleOffAerialSpotTransition=()=>{
    new TWEEN.Tween(this.crosshair.scale)
      .to({x: 1, y: 1, z: 1}, 1000)
      .onStart(() => { this.isDownAerialSpot=true })
      .onComplete(() => { this.isDownAerialSpot=false })
      .start();
  }
  handleAnnotationVisible=()=>{
    if ( this.annotations.children.length) {
      let annotationIntersect = this.raycaster.intersectObjects( this.annotations.children,true );
      if (annotationIntersect.length) {
          let selectedAnnot=annotationIntersect[0].object.parent
          store.dispatch(annotationVisible(true));
          store.dispatch(annotationDescriptionSupply({title:selectedAnnot.title,body:selectedAnnot.body,url:selectedAnnot.url}));
      }
    }
  }

  clickOnHotspots(event) {
    if (event.changedTouches) {
      this.mouse.x =
        (event.changedTouches[0].pageX / this.container.clientWidth) * 2 - 1;
      this.mouse.y =
        -(event.changedTouches[0].pageY / this.container.clientHeight) * 2 + 1;
    } else {
      this.mouse.x = (event.clientX / this.container.clientWidth) * 2 - 1;
      this.mouse.y = -(event.clientY / this.container.clientHeight) * 2 + 1;
    }
    this.raycaster.setFromCamera(this.mouse, this._camera);
    this.handleAnnotationVisible()
    // this.aerialTransition()
    this.handleTransition()
  }

  hotspotPlaneRaycaster(intersectsPlane){
    var ang = this.findAngle(
     intersectsPlane[0].uv.x,
     intersectsPlane[0].uv.y,
     intersectsPlane[0].object.userData
    );
    let targert = this.indexOfClosest(this.hotspotAnglesId, ang);
    if (targert) {
     this.removeHotspots(targert.sceneid, 0, true)
    }
  }

  aerialPointRaycaster(intersectsPoints){
   // if (intersectsPoints.length) {
     let selectedObject = intersectsPoints[0].object.parent
     let key = 0
     if (this.mainCategoryData && this.mainCategoryData.data.length) {
       let [category] = this.mainCategoryData.data.filter(obj => {
         return obj.id === selectedObject.mainCategoryId;
       })
       store.dispatch(handleSubCategoryData(category.subCategory))
       store.dispatch(handleMainCategoryTitle(category.name))
       store.dispatch(handleCurrentMainCtaegoryId(category.id-1));
       key = category.subCategory.findIndex(obj => obj.SceneID === selectedObject.sceneId)
     }

     //fix indicator and scrolling in scrollbar at bottom
     store.dispatch(scrollValueFunction(key));

     this.removeHotspots(selectedObject.sceneId, 0, false);
   // }
  }

  handleTransition=()=>{
   let intersectsPoints;
   if ( this._scene.children[2].children[0] &&  this._scene.children[2].children[1]) {
     var intersectsPlane = this.raycaster.intersectObjects(this._scene.children[2].children[1].children);
     if(this.aerialPoints.children.length){
       intersectsPoints = this.raycaster.intersectObjects(this.aerialPoints.children, true);
       if (intersectsPoints.length){
         this.aerialPointRaycaster(intersectsPoints)
       } else if(intersectsPlane.length){
         this.hotspotPlaneRaycaster(intersectsPlane);
       }
     }
     if(intersectsPlane.length && !intersectsPoints ){
       this.hotspotPlaneRaycaster(intersectsPlane);
     }
   }
  }

  removeHotspots(id, opac, animation) {
    return new Promise((resolve,reject) => {
      if(this._scene.children[2].children[0]) {
       this.container.removeEventListener("touchstart", this.touchstartHotspot);
       this.container.removeEventListener("touchmove", this.touchmoveHotspot, false);
       this.container.removeEventListener("touchend", this.touchendHotsport);
       this.container.removeEventListener("mousedown", this.mousedownHotspot);
       this.container.removeEventListener("mouseup", this.mouseupHotspot);

       let lenofhotspots = this._scene.children[2].children[0].children;
       for (let index = 0; index < lenofhotspots.length; index++) {
         this._scene.children[2].children[0].remove(
           this._scene.children[2].children[0].children[index]
         );
       }
       this._scene.children[2].remove(this._scene.children[2].children[0]);

       this._scene.children[2].remove(this._scene.children[2].children[0]);
       let indexforpos = this.sceneData.findIndex(n => n.sceneId === id);
       let position = this.sceneData[indexforpos].position;

       let nextitems = this.sceneData[indexforpos].hotspots.map(x=>x.sceneid);
       // source.cancel('Operation canceled by the user.');
       checkImageDownloaded(id, true, nextitems).then(status => {
         if (status) {
           let stopscript = true
           this.generateCube(id, opac, stopscript).then(x => {
             if(this.autoplayenabled){
               this.autoplayHandler();
             }
             nextdownload()
             this.cameraAnimation(position, animation);
             resolve(true)
           });
         }
       });
     }
     else {
       reject()
     }
    });

  }

  cameraTeleport(id) {
    this.removeHotspots(id, 1, false);
  }

  disposeAerialspot = () => {
    while (this.aerialPoints.children.length) {
      for (var i = 0; i < 5; i++) {
        this.aerialPoints.children[0].children[i].geometry.dispose()
        this.aerialPoints.children[0].children[i].material.dispose()
      }
      this.aerialPoints.remove(this.aerialPoints.children[0])
    }
  }

  disposeAnnotations = () => {
    while (this.annotations.children.length) {
      this.annotations.children[0].children[0].geometry.dispose()
      this.annotations.children[0].children[1].geometry.dispose()
      this.annotations.children[0].children[2].geometry.dispose()
      this.annotations.children[0].children[0].material.dispose()
      this.annotations.children[0].children[1].material.dispose()
      this.annotations.children[0].children[2].material.dispose()
      this.annotations.remove(this.annotations.children[0])
    }
  }

  addAnnotations = (annotationData) => {
    this.annotation.loadSpots().then((spot)=>{
      annotationData.map((annot,index0)=>{
        let newSpot = spot.clone()
        newSpot.body=annot.body
        newSpot.title=annot.title
        newSpot.url=annot.image || null ;
        newSpot.position.set(annot.position.x,annot.position.y,annot.position.z)
        newSpot.rotation.set(annot.rotation.x,annot.rotation.y,annot.rotation.z)
        this.annotations.add(newSpot)
      })
    })
  }

  disposeCube() {
    this._scene.children[2].geometry.dispose();
    for (let index = 0; index < 6; index++) {
      this._scene.children[2].material[index].map.dispose();
      this._scene.children[2].material[index].dispose();
    }
    this._scene.remove(this._scene.children[2]);
  }

}

export default CubeAdd;
