// import SVGLoader from  'three-svg-loader';
import {
  Group,
  MeshBasicMaterial,
  Mesh,
  DoubleSide,
  Geometry,
  RingBufferGeometry,
  CircleBufferGeometry,
  PlaneGeometry
} from 'three';

class Annotation  {
  constructor() {

  }

  loadSpots=()=>{
    let  group = new Group();
    group.name='annotation-spot'

    return new Promise(function(resolve, reject) {
      var geometry = new RingBufferGeometry( 0.6, 0.8, 30 );
      var material = new MeshBasicMaterial( { color: 'white', side: DoubleSide } );
      var mesh = new Mesh( geometry, material );
      var geometry1 = new CircleBufferGeometry( 0.4, 32 );
      var material1 = new MeshBasicMaterial( { color: 'white' , side: DoubleSide} );
      var circle = new Mesh( geometry1, material1 );

      var PlaneMesh = new MeshBasicMaterial({
        side: DoubleSide,
        transparent: true,
        opacity: 0,
        alphaTest: 0.9
      });
      let Plain = new Mesh(
        new PlaneGeometry(1.2, 1.2),
        PlaneMesh
      );
      Plain.rotation.x=0
      Plain.position.z=-0.01

      group.lookAt(0,0,0)
      group.scale.set(4,4,4)
      group.add(Plain)
      group.add(circle)
      group.add(mesh)

      resolve(group)
  })
}
}

export default Annotation
