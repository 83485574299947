import React, { Component } from "react";
import Ripples from "react-ripples";
import playBtn from "../../images/intro/Play.svg";
import revlityLogo from "../../images/intro/Revlity Logo.svg";
import loader from "../../images/intro/loader.svg";
import "./homepage.css";
import  {pendingItems,downloadInitialLevel} from '../../download-manager';
import { connect } from "react-redux";

const autoatuoplay = window.location.search==='?autoplay=true'
const autoStart = window.location.search ==='?autostart=true'

 class HomePage extends Component {
  // state = {
  //   isLoading: false
  // };

  componentDidMount() {

    if (autoatuoplay || autoStart) {
      setTimeout(_ => {
        this.handleClick(this.props.firstScene)
      }, 100);
    }
  }

  handleClick = (id1) => {
    // this.setState((prev, cur) => {
    //   return {
    //     isLoading: !prev.isLoading
    //   };
    // });
    pendingItems();
    downloadInitialLevel(id1, true);
  };

  render() {
    // const { isLoading } = this.state;
    var { banner, logo, name, creator, isLoading , firstScene, creatorLogo} = this.props;
    return (
      <div style={{position:"fixed"}}>
        <Ripples during={1900} color={"rgba(0, 0, 0, .1)"}>
          <div onClick={()=>this.handleClick(firstScene)} className="clickable">
            <div className="backdrop">
              <img src={banner} alt="" />
            </div>
            <img className="logo" src={logo} alt="logo" />
            <img
              className="playBtn"
              src={isLoading ? loader : playBtn}
              alt="play button"
            />
            <h1>{name}</h1>
            <div className="yellowBar" style={{backgroundColor: this.props.brandColor}} />
            <p className="isLoading">
              {isLoading ? "Loading virtual tour..." : ""}
            </p>
          </div>
        </Ripples>
        <div className="footer">
          {
            creatorLogo?
          <img src={creatorLogo} alt="" />:
          <p>{creator}</p>
          }
          <img src={revlityLogo} alt="" />
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    brandColor: state.sceneDataReducer.brandColor
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // scrollValueFunction: data => dispatch(scrollValueFunction(data))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
